import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { AuthProvider } from "react-oidc-context";
import "./index.css";
import { store } from "./store/store";
import { oidcConfig } from "./auth/constants";
import { router } from "./router";

initializeIcons();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <AuthProvider {...oidcConfig}>
        <React.StrictMode>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </React.StrictMode>
    </AuthProvider>
);

import { useMemo } from "react";
import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { ChatAppResponse, ChatAppResponseType } from "../../api/models";
import { getCitationFilePath } from "../../api/api";
import { parseAnswer } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

const SYNERGI_LIFE_CASE_URL = import.meta.env.VITE_SYNERGI_LIFE_CASE_URL;

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onLoginClicked: () => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onFollowupQuestionClicked,
    onLoginClicked,
    showFollowupQuestions
}: Props) => {
    const followupQuestions: string[] = [];
    const messageContent =
        answer?.choices?.[0]?.message?.content ?? answer + "";
    const parsedAnswer = useMemo(
        () => parseAnswer(messageContent, isStreaming),
        [answer]
    );
    const sanitizedAnswer = DOMPurify.sanitize(parsedAnswer.answer);

    return (
        <Stack
            className={`${styles.answerContainer} ${isSelected && styles.selected}`}
            verticalAlign="space-between"
        >
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText}>{sanitizedAnswer}</div>

                {answer?.responseType === ChatAppResponseType.case && (
                    <a
                        href={SYNERGI_LIFE_CASE_URL! + answer.caseId}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Click here to open your case in Synergi Life
                    </a>
                )}

                {answer?.responseType === ChatAppResponseType.unauthorized && (
                    <a className={styles.link} onClick={onLoginClicked}>
                        Click here to login again
                    </a>
                )}
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>
                            Citations:
                        </span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a
                                    key={i}
                                    className={styles.citation}
                                    title={x}
                                    onClick={() => onCitationClicked(path)}
                                >
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length &&
                showFollowupQuestions &&
                onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack
                            horizontal
                            wrap
                            className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                            tokens={{ childrenGap: 6 }}
                        >
                            <span className={styles.followupQuestionLearnMore}>
                                Follow-up questions:
                            </span>
                            {followupQuestions.map((x: string, i: number) => {
                                return (
                                    <a
                                        key={i}
                                        className={styles.followupQuestion}
                                        title={x}
                                        onClick={() =>
                                            onFollowupQuestionClicked(x)
                                        }
                                    >
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
        </Stack>
    );
};

import { Sparkle28Filled } from "@fluentui/react-icons";

export const AnswerIcon = () => {
    return (
        <Sparkle28Filled
            primaryFill={"#91FFB4"}
            aria-hidden="true"
            aria-label="Answer logo"
        />
    );
};

import { CaseDraft } from "../store/types";

export type ResponseMessage = {
    content?: string;
    role?: string;
};

export type ResponseChoice = {
    index?: number;
    message?: ResponseMessage;
    messages?: ResponseMessage[];
};

export type ChatAppResponse = {
    error?: string;
    choices?: ResponseChoice[];
    delta?: ResponseMessage;
    responseType?: ChatAppResponseType;
    caseId?: number;
};

export const enum ChatAppResponseType {
    case = "case",
    unauthorized = "unauthorized"
}

export type ChatAppRequest = {
    messages: ResponseMessage[];
    draft?: CaseDraft;
};

import { AuthContextProps } from "react-oidc-context";
import {
    setLastQuestionRef,
    setError,
    setIsLoading,
    setActiveCitation,
    setAnswers
} from "../store/appSlice";
import { AppDispatch } from "../store/store";
import { postChatApi } from "./api";
import { ResponseMessage, ChatAppRequest } from "./models";
import { handleResponse } from "./response";
import { Answer } from "../types";
import { ApiError, CaseDraft } from "../store/types";

export const handleRequest = (
    dispatch: AppDispatch,
    error: ApiError | undefined,
    answers: Answer[],
    auth: AuthContextProps,
    lastQuestionRef?: React.MutableRefObject<string>
) => {
    return async (question: string, draft?: CaseDraft) => {
        dispatch(setLastQuestionRef({ ...lastQuestionRef, current: question }));

        error && dispatch(setError(undefined));
        dispatch(setIsLoading(true));
        dispatch(setActiveCitation(undefined));

        try {
            const messages: ResponseMessage[] = answers.flatMap(answer => [
                { content: answer[0], role: "user" },
                {
                    content: answer?.[1]?.choices?.[0]?.message?.content,
                    role: "assistant"
                }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                draft
            };

            const response = await postChatApi(request, {
                accessToken: auth.user?.access_token
            });

            if (!response.body) {
                throw Error("No response body");
            }

            const parsedResponse = await handleResponse(dispatch)(
                question,
                answers,
                response
            );
            dispatch(setAnswers([...answers, [question, parsedResponse]]));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

import { useAuth } from "react-oidc-context";
import {
    selectActiveCitation,
    selectIsStreaming,
    selectSelectedAnswer,
    setSelectedAnswer
} from "../../store/appSlice";
import { triggerSigninRedirect } from "../../auth/triggerSigninRedirect";
import { onShowCitation } from "../../pages/chat/callbacks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Answer, AnswerLoading, AnswerError } from "../Answer";
import { UserChatMessage } from "../UserChatMessage";
import styles from "./ChatMessageStream.module.css";
import { ChatMessageStreamProps } from "./types";

export const ChatMessageStream = ({
    streamedAnswers,
    answers,
    isLoading,
    error,
    lastQuestionRef,
    makeApiRequest,
    chatMessageStreamEnd
}: ChatMessageStreamProps) => {
    const auth = useAuth();

    const dispatch = useAppDispatch();

    const isStreaming = useAppSelector(selectIsStreaming);
    const activeCitation = useAppSelector(selectActiveCitation);
    const selectedAnswer = useAppSelector(selectSelectedAnswer);

    const onCitationClicked = onShowCitation(
        activeCitation,
        selectedAnswer,
        dispatch
    );

    const onToggleTab = (index: number) => {
        dispatch(setSelectedAnswer(index));
    };
    return (
        <div className={styles.chatMessageStream}>
            {isStreaming &&
                streamedAnswers.map((streamedAnswer, index) => {
                    return (
                        <div key={index}>
                            <UserChatMessage message={streamedAnswer[0]} />
                            <div className={styles.chatMessageGpt}>
                                <Answer
                                    isStreaming={true}
                                    key={index}
                                    answer={streamedAnswer[1]}
                                    isSelected={false}
                                    onCitationClicked={c =>
                                        onCitationClicked(c, index)
                                    }
                                    onThoughtProcessClicked={() =>
                                        onToggleTab(index)
                                    }
                                    onSupportingContentClicked={() =>
                                        onToggleTab(index)
                                    }
                                    onFollowupQuestionClicked={q =>
                                        makeApiRequest(q)
                                    }
                                    onLoginClicked={triggerSigninRedirect(auth)}
                                    showFollowupQuestions={false}
                                />
                            </div>
                        </div>
                    );
                })}
            {!isStreaming &&
                answers.map((answer, index) => (
                    <div key={index}>
                        <UserChatMessage message={answer[0]} />
                        <div className={styles.chatMessageGpt}>
                            <Answer
                                isStreaming={false}
                                key={index}
                                answer={answer[1]}
                                isSelected={false}
                                onCitationClicked={c =>
                                    onCitationClicked(c, index)
                                }
                                onThoughtProcessClicked={() =>
                                    onToggleTab(index)
                                }
                                onSupportingContentClicked={() =>
                                    onToggleTab(index)
                                }
                                onFollowupQuestionClicked={q =>
                                    makeApiRequest(q)
                                }
                                onLoginClicked={triggerSigninRedirect(auth)}
                                showFollowupQuestions={false}
                            />
                        </div>
                    </div>
                ))}
            {isLoading && !isStreaming && (
                <>
                    <UserChatMessage message={lastQuestionRef?.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                        <AnswerLoading />
                    </div>
                </>
            )}
            {error ? (
                <>
                    <UserChatMessage message={lastQuestionRef?.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                        <AnswerError
                            error={error.toString()}
                            onRetry={() =>
                                makeApiRequest(lastQuestionRef?.current)
                            }
                        />
                    </div>
                </>
            ) : null}
            <div ref={chatMessageStreamEnd} />
        </div>
    );
};

import {
    setIsStreaming,
    setStreamedAnswers,
    setIsLoading,
    setDraft
} from "../store/appSlice";
import { AppDispatch } from "../store/store";
import { ChatAppResponse, ChatAppResponseType } from "./models";
import readNDJSONStream from "ndjson-readablestream";
import { Answer } from "../types";
import {
    CaseCreationStatus,
    caseCreatedText,
    caseRegistrationConfirmationText,
    genericErrorText,
    sessionExpiredText
} from "./constants";
import { HttpStatus } from "./http";
import { LLMResponse } from "./types";

export const handleResponse =
    (dispatch: AppDispatch) =>
    async (
        question: string,
        answers: Answer[],
        response: Response
    ): Promise<ChatAppResponse> => {
        try {
            const streamOutput = await handleStream(
                response,
                dispatch,
                question,
                answers
            );

            dispatch(setIsStreaming(false));

            return buildAppResponse(
                streamOutput.appResponse,
                streamOutput.answer
            );
        } catch (err) {
            console.log("Error ", err);

            dispatch(setIsStreaming(false));

            return buildAppResponse();
        }
    };

const buildAppResponse = (
    appResponse: ChatAppResponse = {},
    answer: string = ""
): ChatAppResponse => ({
    ...appResponse,
    choices: [
        {
            ...appResponse?.choices?.[0],
            message: {
                content: answer,
                role: appResponse?.choices?.[0]?.messages?.[1]?.role
            }
        }
    ]
});

const handleStream = async (
    response: Response,
    dispatch: AppDispatch,
    question: string,
    answers: Answer[]
) => {
    let answer: string = "";
    let appResponse: ChatAppResponse = {};

    for await (const untypedLLMResponse of readNDJSONStream(response.body!)) {
        const llmResponse = untypedLLMResponse as LLMResponse;

        if (hasCaseCreationResult(llmResponse)) {
            ({ appResponse, answer } = await handleCaseCreationResult(
                llmResponse,
                response,
                appResponse,
                dispatch,
                answer,
                question,
                answers
            ));
        } else if (hasDraftCase(llmResponse)) {
            ({ appResponse, answer } = await handleDraftCaseResponse(
                llmResponse,
                appResponse,
                dispatch,
                answer,
                question,
                answers
            ));
        } else if (hasRegularResponse(llmResponse)) {
            ({ appResponse, answer } = await handleRegularResponse(
                dispatch,
                appResponse,
                llmResponse,
                answer,
                question,
                answers
            ));
        } else if (isStreaming(llmResponse)) {
            ({ appResponse, answer } = await handleStreaming(
                appResponse,
                llmResponse,
                answer,
                dispatch,
                question,
                answers
            ));
        } else if (hasError(llmResponse)) {
            throw Error(llmResponse.error);
        } else {
            dispatch(setIsStreaming(true));
        }
    }
    return { appResponse, answer };
};

const buildAnswer = (
    dispatch: AppDispatch,
    answer: string,
    newContent: string | undefined,
    appResponse: ChatAppResponse,
    question: string,
    answers: Answer[]
): Promise<string> => {
    if (newContent === undefined) {
        return Promise.resolve(answer ?? "");
    }

    return new Promise(resolve => {
        setTimeout(() => {
            answer += newContent;

            const latestResponse: ChatAppResponse = {
                ...appResponse,
                ...(appResponse?.choices?.length &&
                    appResponse?.choices?.length > 0 &&
                    buildChatAppResponse(appResponse, answer)),
                ...(!appResponse?.choices?.length &&
                    appResponse?.delta?.content &&
                    buildChatAppResponse(appResponse, answer))
            };

            dispatch(setIsStreaming(true));
            dispatch(
                setStreamedAnswers([...answers, [question, latestResponse]])
            );

            resolve(answer);
        }, 33);
    });
};

const handleCaseCreationResult = async (
    llmResponse: LLMResponse,
    response: Response,
    appResponse: ChatAppResponse,
    dispatch: AppDispatch,
    answer: string,
    question: string,
    answers: Answer[]
) => {
    const message =
        llmResponse.caseCreationStatus === CaseCreationStatus.created
            ? caseCreatedText(llmResponse.caseId)
            : response.status === HttpStatus.Unauthorized
              ? sessionExpiredText
              : genericErrorText;

    llmResponse = {
        ...llmResponse,
        choices: [{ message: { role: "assistant", content: message } }]
    };

    appResponse = llmResponse;
    appResponse.responseType =
        response.status === HttpStatus.Unauthorized
            ? ChatAppResponseType.unauthorized
            : ChatAppResponseType.case;
    appResponse.caseId = llmResponse.caseId;

    dispatch(setIsStreaming(false));
    dispatch(setIsLoading(false));
    dispatch(setDraft(undefined));

    answer = await buildAnswer(
        dispatch,
        answer,
        message,
        appResponse,
        question,
        answers
    );

    return { appResponse, answer };
};

const handleDraftCaseResponse = async (
    llmResponse: LLMResponse,
    appResponse: ChatAppResponse,
    dispatch: AppDispatch,
    answer: string,
    question: string,
    answers: Answer[]
) => {
    const message = caseRegistrationConfirmationText(llmResponse.case);

    if (llmResponse?.choices?.[0]?.message) {
        llmResponse.choices[0].message.content = message;
    } else {
        llmResponse = {
            ...llmResponse,
            choices: [
                {
                    message: {
                        role: "assistant",
                        content: message
                    }
                }
            ]
        };
    }

    appResponse = llmResponse;

    dispatch(setIsStreaming(false));
    dispatch(setIsLoading(false));
    dispatch(setDraft(llmResponse.case));

    answer = await buildAnswer(
        dispatch,
        answer,
        message,
        appResponse,
        question,
        answers
    );

    return { appResponse, answer };
};

const hasCaseCreationResult = (llmResponse: LLMResponse) =>
    llmResponse.caseCreationStatus;

const hasDraftCase = (llmResponse: LLMResponse) => llmResponse.case;

const hasRegularResponse = (llmResponse: LLMResponse) =>
    !!getRegularResponseContent(llmResponse);

const getRegularResponseContent = (llmResponse: LLMResponse) =>
    llmResponse?.choices?.[0]?.messages?.[1]?.content ||
    llmResponse?.choices?.[0]?.message?.content;

const handleRegularResponse = async (
    dispatch: AppDispatch,
    appResponse: ChatAppResponse,
    llmResponse: LLMResponse,
    answer: string,
    question: string,
    answers: Answer[]
) => {
    dispatch(setIsLoading(false));

    appResponse = llmResponse;

    answer = await buildAnswer(
        dispatch,
        answer,
        getRegularResponseContent(llmResponse),
        appResponse,
        question,
        answers
    );

    return { appResponse, answer };
};

const isStreaming = (llmResponse: LLMResponse) => llmResponse.delta;

const handleStreaming = async (
    appResponse: ChatAppResponse,
    llmResponse: LLMResponse,
    answer: string,
    dispatch: AppDispatch,
    question: string,
    answers: Answer[]
) => {
    appResponse = llmResponse;

    answer = await buildAnswer(
        dispatch,
        answer,
        llmResponse.delta?.content,
        appResponse,
        question,
        answers
    );

    return { appResponse, answer };
};

const hasError = (llmResponse: LLMResponse) => llmResponse.error;

const buildChatAppResponse = (
    appResponse: ChatAppResponse,
    answer: string
) => ({
    choices: [
        {
            ...appResponse?.choices?.[0],
            message: {
                content: answer,
                role: appResponse.choices?.[0]?.messages?.[1]?.role
            }
        }
    ]
});

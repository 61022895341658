import React from "react";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { createHashRouter } from "react-router-dom";

export const router = createHashRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

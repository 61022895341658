import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "What example questions related to Synergi Life I can ask you?",
    "What are the steps to change a user ID or password in Synergi Life?",
    "How can I create a user?"
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {DEFAULT_EXAMPLES.map((question, i) => (
                <li key={i}>
                    <Example
                        text={question}
                        value={question}
                        onClick={onExampleClicked}
                    />
                </li>
            ))}
        </ul>
    );
};

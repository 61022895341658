import { createSlice } from "@reduxjs/toolkit";
import { MutableRefObject } from "react";
import { ApiError, CaseDraft } from "./types";
import { Answer } from "../types";

const initialState: AppSliceState = {
    answers: [],
    streamedAnswers: [],
    isLoading: false,
    isStreaming: false,
    lastQuestionRef: undefined,
    error: undefined,
    draft: undefined,
    activeCitation: undefined,
    selectedAnswer: 0
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        clearChat(state) {
            state.lastQuestionRef
                ? (state.lastQuestionRef.current = "")
                : (state.lastQuestionRef = undefined);
            state.error = undefined;
            state.streamedAnswers = [];
            state.isLoading = false;
            state.isStreaming = false;
            state.answers = [];
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setLastQuestionRef(state, action) {
            state.lastQuestionRef = action.payload;
        },
        setIsStreaming(state, action) {
            state.isStreaming = action.payload;
        },
        setDraft(state, action) {
            state.draft = action.payload;
        },
        setActiveCitation(state, action) {
            state.activeCitation = action.payload;
        },
        setSelectedAnswer(state, action) {
            state.selectedAnswer = action.payload;
        },
        setAnswers(state, action) {
            state.answers = action.payload;
        },
        setStreamedAnswers(state, action) {
            state.streamedAnswers = action.payload;
        }
    },
    selectors: {
        selectIsClearingAnswersDisabled: state =>
            !state.lastQuestionRef?.current || state.isLoading,
        selectIsLoading: state => state.isLoading,
        selectLastQuestionRef: state => state.lastQuestionRef,
        selectIsStreaming: state => state.isStreaming,
        selectDraft: state => state.draft,
        selectError: state => state.error,
        selectActiveCitation: state => state.activeCitation,
        selectSelectedAnswer: state => state.selectedAnswer,
        selectAnswers: state => state.answers,
        selectStreamedAnswers: state => state.streamedAnswers
    }
});

export interface AppSliceState {
    answers: Answer[];
    streamedAnswers: Answer[];
    isLoading: boolean;
    isStreaming: boolean;
    lastQuestionRef?: MutableRefObject<string>;
    error?: ApiError;
    draft?: CaseDraft;
    activeCitation?: string;
    selectedAnswer: number;
}

export const {
    selectIsClearingAnswersDisabled,
    selectIsLoading,
    selectLastQuestionRef,
    selectIsStreaming,
    selectDraft,
    selectError,
    selectActiveCitation,
    selectSelectedAnswer,
    selectAnswers,
    selectStreamedAnswers
} = appSlice.selectors;

export const {
    clearChat,
    setIsLoading,
    setIsStreaming,
    setDraft,
    setError,
    setLastQuestionRef,
    setActiveCitation,
    setSelectedAnswer,
    setAnswers,
    setStreamedAnswers
} = appSlice.actions;

export const appReducer = appSlice.reducer;

type ParsedAnswer = {
    answer: string;
    citations: string[];
};

export function parseAnswer(
    answer: string,
    isStreaming: boolean
): ParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        }

        if (citations.indexOf(part) === -1) {
            citations.push(part);
        }

        return "";
    });

    return {
        answer: fragments.join("").replace(/\*\*/g, ""),
        citations: []
    };
}

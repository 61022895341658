import { setActiveCitation, setSelectedAnswer } from "../../store/appSlice";
import { AppDispatch } from "../../store/store";

export const onShowCitation = (
    activeCitation: string | undefined,
    selectedAnswer: number,
    dispatch: AppDispatch
) => {
    return (citation: string, index: number) => {
        if (!(activeCitation === citation && selectedAnswer === index)) {
            dispatch(setActiveCitation(citation));
        }

        dispatch(setSelectedAnswer(index));
    };
};

import styles from "./Chat.module.css";
import { QuestionInput } from "../../components/QuestionInput";
import { useAuth } from "react-oidc-context";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    selectAnswers,
    selectDraft,
    selectError,
    selectIsLoading,
    selectLastQuestionRef,
    selectStreamedAnswers
} from "../../store/appSlice";
import { ensureAuthenticated } from "../../auth/helpers";
import { handleRequest } from "../../api/request";
import { EmptyChat } from "../../components/EmptyChat/EmptyChat";
import { ChatMessageStream } from "../../components/ChatMessageStream/ChatMessageStream";
import { useRef } from "react";
import { scrollOnLoad, scrollOnStream } from "./hooks";

const Chat = () => {
    const auth = useAuth();

    ensureAuthenticated(auth);

    const isLoading = useAppSelector(selectIsLoading);
    const lastQuestionRef = useAppSelector(selectLastQuestionRef);
    const draft = useAppSelector(selectDraft);
    const error = useAppSelector(selectError);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const answers = useAppSelector(selectAnswers);
    const streamedAnswers = useAppSelector(selectStreamedAnswers);

    const dispatch = useAppDispatch();

    const makeApiRequest = handleRequest(
        dispatch,
        error,
        answers,
        auth,
        lastQuestionRef
    );

    scrollOnLoad(isLoading, chatMessageStreamEnd);
    scrollOnStream(streamedAnswers, chatMessageStreamEnd);

    if (!auth?.isAuthenticated) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef?.current ? (
                        <EmptyChat makeApiRequest={makeApiRequest} />
                    ) : (
                        <ChatMessageStream
                            streamedAnswers={streamedAnswers}
                            answers={answers}
                            chatMessageStreamEnd={chatMessageStreamEnd}
                            error={error}
                            isLoading={isLoading}
                            lastQuestionRef={lastQuestionRef}
                            makeApiRequest={makeApiRequest}
                        />
                    )}
                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question (e.g. what are default values?)"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question, draft)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;

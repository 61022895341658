import { Outlet, Link } from "react-router-dom";
import { Delete24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import styles from "./Layout.module.css";
import { Bug24Regular, ArrowExit20Regular } from "@fluentui/react-icons";
import {
    clearChat,
    selectIsClearingAnswersDisabled
} from "../../store/appSlice";
import { MouseEventHandler } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useAuth } from "react-oidc-context";
import { oidcClientId, oidcServiceUrl } from "../../infrastructure/config";

const email = import.meta.env.VITE_REPORT_BUG_EMAIL ?? "";

const Layout = () => {
    const auth = useAuth();

    const handleBugReportClick = () => {
        const subject = encodeURIComponent("Synergi Life AI Bug Report");
        const body = encodeURIComponent("Hello, \n\n");
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    };

    const disabled = useAppSelector(selectIsClearingAnswersDisabled);
    const dispatch = useAppDispatch();

    const onClearChat: MouseEventHandler = () => dispatch(clearChat());

    if (!auth.isAuthenticated) {
        return <Outlet />;
    }

    const logout = () => {
        sessionStorage.removeItem(
            `oidc.user:${oidcServiceUrl}:${oidcClientId}`
        );
        auth.signoutRedirect({ id_token_hint: auth?.user?.id_token });
    };

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerLogoContainer}>
                        <img src="logo.png" alt="DNV" width="98" height="62" />
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h3 className={styles.headerTitle}>
                                Synergi Life AI
                            </h3>
                        </Link>
                    </div>
                    <nav>
                        <ul className={styles.headerNavList}></ul>
                    </nav>
                    <nav className={styles.headerRightText}>
                        <ul className={styles.headerNavList}>
                            <li>
                                <div>
                                    <Button
                                        icon={<Delete24Regular />}
                                        disabled={disabled}
                                        onClick={onClearChat}
                                    >
                                        <span
                                            className={styles.headerButtonText}
                                        >
                                            Clear chat
                                        </span>
                                    </Button>
                                </div>
                            </li>
                            <li>|</li>
                            <li>
                                <div>
                                    <Button
                                        icon={<Bug24Regular />}
                                        onClick={handleBugReportClick}
                                    >
                                        <span
                                            className={styles.headerButtonText}
                                        >
                                            Report a bug
                                        </span>
                                    </Button>
                                </div>
                            </li>
                            <li>|</li>
                            <li>
                                <div>
                                    <Button
                                        icon={<ArrowExit20Regular />}
                                        onClick={logout}
                                    >
                                        <span
                                            className={styles.headerButtonText}
                                        >
                                            Sign out
                                        </span>
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;

import { useEffect } from "react";
import { Answer } from "../../types";

export const scrollOnLoad = (
    isLoading: boolean,
    chatMessageStreamEnd?: React.MutableRefObject<HTMLDivElement | null>
) => {
    useEffect(
        () =>
            chatMessageStreamEnd?.current?.scrollIntoView({
                behavior: "smooth"
            }),
        [isLoading, chatMessageStreamEnd]
    );
};

export const scrollOnStream = (
    streamedAnswers: Answer[],
    chatMessageStreamEnd?: React.MutableRefObject<HTMLDivElement | null>
) => {
    useEffect(
        () =>
            chatMessageStreamEnd?.current?.scrollIntoView({ behavior: "auto" }),
        [streamedAnswers, chatMessageStreamEnd]
    );
};

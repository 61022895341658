import { SparkleFilled } from "@fluentui/react-icons";
import { ExampleList } from "../Example";
import { EmptyChatProps } from "./types";
import styles from "./EmptyChat.module.css";

export const EmptyChat = ({ makeApiRequest }: EmptyChatProps) => {
    return (
        <div className={styles.chatEmptyState}>
            <SparkleFilled
                fontSize={"120px"}
                primaryFill={"#91FFB4"}
                aria-hidden="true"
                aria-label="Chat logo"
            />
            <h1 className={styles.chatEmptyStateTitle}>
                Chat with Synergi Life AI
            </h1>
            <h2 className={styles.chatEmptyStateSubtitle}>
                Ask anything or try an example
            </h2>
            <ExampleList onExampleClicked={makeApiRequest} />
        </div>
    );
};
